

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto, sans-serif
}

html {
    overflow-x: hidden
}

a {
    text-decoration: none;
    color: black;
    font-weight: 400;
    outline: none
}

section {
    background-color: #eaeaea;
    padding: 20px 0;
    min-height: 100vh
}

header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 80px;
    background-color: #2b6777;
    padding: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9999
}

@media (max-width:500px) {
    header {
        height: 110px
    }
}

header .logo {
    margin-right: 15px
}

@media (max-width:500px) {
    header .logo {
        position: absolute;
        top: 10px;
        left: 20px
    }
}

header #input-search {
    width: 250px;
    padding: 5px 8px;
    font-size: 18px;
    outline: none
}

@media (max-width:500px) {
    header #input-search {
        position: absolute;
        bottom: 15px;
        left: 20px
    }
}

@media (max-width:300px) {
    header #input-search {
        width: 220px
    }
}

@media (max-width:878px) {
    header ul {
        position: fixed;
        width: 100%;
        height: 50vh;
        padding-top: 20px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-around;
        justify-content: space-around;
        -webkit-align-items: center;
        align-items: center;
        -webkit-flex-direction: column;
        flex-direction: column;
        z-index: 99;
        transition: .7s ease-in-out
    }
}

header ul li {
    list-style: none;
    display: inline-block;
    padding: 0 10px;
    font-size: 18px
}

@media (max-width:878px) {
    header ul li .menu {
        position: absolute;
        top: 20px;
        right: 20px
    }
}

header ul li a {
    padding: 10px 20px;
    border-radius: 20px;
    transition: .7s
}

header ul li a:hover {
    background-color: #f2f2f2;
    color: #2b6777
}

header .cart-icon {
    position: relative
}

@media (max-width:500px) {
    header .cart-icon {
        top: -55px;
        right: -30px
    }
}

header .cart-icon span {
    background-color: #c20d0d;
    color: #f2f2f2;
    border-radius: 50%;
    position: absolute;
    bottom: 25px;
    right: 6px;
    font-weight: 700;
    padding: 4px;
    font-size: 12px
}

@media (max-width:500px) {
    header .cart-icon span {
        bottom: -27px;
        right: 27px;
        padding: 3px;
        z-index: 1
    }
}

.menu {
    display: none
}

@media (max-width:878px) {
    .menu {
        display: block;
        cursor: pointer
    }
}

@media (max-width:500px) {
    .menu {
        position: absolute;
        top: 20px;
        right: 20px
    }
}

.landing-box div h1,
.landing-box div p {
    padding: 10px;
    letter-spacing: 1px;
    word-spacing: 2px
}

.landing-box {
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto 20px
}

.landing-box,
.landing-box div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center
}

.landing-box div {
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-direction: column;
    flex-direction: column;
    max-width: 600px;
    padding: 0 10px;
    text-align: center;
    margin-bottom: 20px
}

.landing-box div h1 {
    font-size: 32px
}

@media (max-width:400px) {
    .landing-box div h1 {
        font-size: 20px
    }
}

.landing-box div p {
    font-size: 22px;
    margin-bottom: 15px;
    color: #2b6777
}

@media (max-width:400px) {
    .landing-box div p {
        font-size: 16px
    }
}

.landing-box div a {
    border: 2px solid #2b6777;
    color: #2b6777;
    padding: 10px 20px;
    border-radius: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    font-weight: 700;
    font-size: 20px;
    transition: all .7s
}

@media (max-width:400px) {
    .landing-box div a {
        font-size: 16px;
        padding: 8px 16px
    }
}

.landing-box div a:hover {
    background-color: #2b6777;
    color: #f2f2f2
}

.landing-box img {
    width: 480px;
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    background-color: #adc5d8;
    margin-right: 10px
}

@media (max-width:500px) {
    .landing-box img {
        width: 380px
    }
}

@media (max-width:400px) {
    .landing-box img {
        width: 300px
    }
}

@media (max-width:300px) {
    .landing-box img {
        width: 260px
    }
}

.features {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto
}

.features .features-card {
    border: 2px solid #2b6777;
    width: 250px;
    margin: 10px;
    padding: 10px;
    background-color: #c8d8e4;
    transition: box-shadow .3s;
    border-radius: 10px;
    cursor: pointer
}

.features .features-card:hover {
    box-shadow: 0 2px 6px #2b6777
}

.features .features-head {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
    color: #2b6777;
    font-weight: 500
}

.features .features-para {
    text-align: center
}

footer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
    background-color: #2b6777;
    color: #f2f2f2;
    height: 150px
}

@media (max-width:550px) {
    footer {
        height: 200px
    }
}

@media (max-width:400px) {
    footer {
        height: 250px
    }
}

footer .footer-links {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto 15px;
    padding-top: 10px
}

footer .footer-links a {
    padding: 10px 15px
}

@media (max-width:300px) {
    footer .footer-links a {
        font-size: 15px
    }
}

footer .copyright {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    font-size: 14px;
    width: 300px;
    padding-bottom: 10px
}

@media (max-width:300px) {
    footer .copyright {
        font-size: 13px
    }
}

footer .copyright p {
    margin-right: 10px
}




.products {
    display: -webkit-flex;
    display: inline-flex;

    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto
}

.products .products-card {
    width: 300px;
    height: 500px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 2px 6px #555;
    padding: 15px;
    margin: 15px
}

.products .products-card img {
    width: 100%;
    height: 100%;
    max-height: 290px;
    object-fit: contain
}








.products .products-content {
    margin: 5px 0;
    text-align: center
}

.products .products-content h3 {
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.products .products-content h3 a {
    color: #000
}

.products .products-content h3 a:hover {
    color: #2b6777
}

.products .products-desc {
    margin: 5px;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 70px;
    text-align: justify
}

.products .products-price {
    font-size: 20px;
    font-weight: 700;
    color: #c20d0d
}

.products button {
    background-color: #2b6777;
    color: #f2f2f2;
    width: 100%;
    border: none;
    outline: none;
    padding: 10px 0;
    margin: 5px 0;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 700
}

.details {
    display: -webkit-flex;
    display: flex;
    border-radius: 20px;
    /* -webkit-justify-content: space-evenly; */
    /* justify-content: space-evenly; */
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    /* max-width: 1100px; */
    margin: 0 auto;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px
}



.details .details-img {
    /* border: 2px solid #2b6777; */
    /* width: 350px;
    height: 450px; */
    margin: 20px;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat
}



@media (max-width:350px) {
    .details .details-img {
        height: 300px
    }
}

.details .details-img:hover {
    background-size: 150%;
    cursor: crosshair
}

.details .details-content {
    /* max-width: 500px; */
    margin: 0 20px 20px
}

.details .details-content h2 {
    text-transform: uppercase;
    /* color: #2b6777; */
    letter-spacing: 1px;
    word-spacing: 2px;
    font-size: 35px;
    margin: 10px
}

.details .details-content p {
    line-height: 1.5;
    margin: 10px
}

.details .details-content h3 {
    color: #c20d0d;
    margin: 15px;
    font-size: 30px
}

.details .colors {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    width: 135px;
    margin: 10px
}

@media (max-width:970px) {
    .details .colors {
        margin: auto
    }
}

.details .colors button {
    border: 1px solid #000;
    width: 35px;
    height: 35px;
    outline: none;
    margin-right: 10px;
    cursor: pointer
}

.details .thumb {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-direction: row;
    flex-direction: row;
    margin: 15px;
    width: 250px
}

@media (max-width:970px) {
    .details .thumb {
        margin: auto;
        margin-bottom: 10px;
    }
}

.details .thumb img {
    /* border: 1px solid; */
    width: 100%;
    cursor: pointer;
    object-fit: cover
}

.details .details-addtocart {
    margin: 10px
}

.checkout-btn,
.delete,
.details .details-addtocart {
    background-color: #2b6777;
    color: #f2f2f2;
    cursor: pointer;
    padding: 10px 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700
}

.checkout-btn,
.delete {
    border: none;
    outline: none;
    text-align: center
}

.delete {
    position: absolute;
    bottom: 5px;
    right: 5px;
    margin: 10px;
    font-size: 15px
}

@media (max-width:550px) {
    .delete {
        font-size: 12px;
        padding: 8px 16px
    }
}

@media (max-width:450px) {
    .delete {
        font-size: 10px;
        padding: 6px 12px
    }
}

.checkout-btn {
    margin: 20px auto;
    width: 100%;
    display: inline-block
}

#cart-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    font-size: 3rem;
    text-align: center
}

.cart {
    -webkit-flex-direction: row;
    flex-direction: row;
    margin: 0 auto;
    max-width: 1200px
}

.cart,
.cart .cart-box {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap
}

.cart .cart-box {
    -webkit-flex-direction: column;
    flex-direction: column
}


.card {
    display: -webkit-flex;
    display: flex;
    margin: 10px;
    background-color: #fff;
    box-shadow: 0 2px 6px #555;
    padding: 15px;
    position: relative;
    width: 300px;
    /* height: 450px; */

}

@media (max-width:768px) {
    .card {
        width: 165px;

    }
}


@media (max-width:450px) {
    .card {
        width: 165px;

    }
}

@media (max-width:350px) {
    .card {
        width: 250px
    }
}

.card .card-img {
    width: 250px;
    height: 200px;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    /* border: 2px solid; */
}

@media (max-width:550px) {
    .card .card-img {
        width: 135px;
        height: 120px
    }
}

@media (max-width:768px) {
    .card .card-img {
        width: 135px;
        height: 120px
    }
}



.card .card-content {
    padding: 0 10px 0 15px
}

.card .card-content p {
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 1px;
    word-spacing: 2px;
    margin-bottom: 10px;
    font-weight: 700;


}

@media (max-width:550px) {
    .card .card-content p {
        font-size: 18px
    }
}

@media (max-width:450px) {
    .card .card-content p {
        font-size: 16px
    }
}

@media (max-width:350px) {
    .card .card-content p {
        font-size: 14px
    }
}

.card .card-content h3 {
    color: #c20d0d;
    margin-bottom: 15px;
    font-size: 20px
}

@media (max-width:550px) {
    .card .card-content h3 {
        font-size: 16px;
        margin-bottom: 10px
    }
}

@media (max-width:450px) {
    .card .card-content h3 {
        font-size: 14px
    }
}

@media (max-width:350px) {
    .card .card-content .amount {
        margin-bottom: 35px
    }
}

.card .card-content .amount .count {
    width: 30px;
    height: 30px;
    border: 1px solid #555;
    background-color: #2b6777;
    color: #f2f2f2;
    outline: none;
    font-size: 20px;
    cursor: pointer
}

@media (max-width:550px) {
    .card .card-content .amount .count {
        width: 25px;
        height: 25px
    }
}

@media (max-width:450px) {
    .card .card-content .amount .count {
        width: 20px;
        height: 20px;
        font-size: 15px
    }
}

.card .card-content .amount span {
    color: #c20d0d;
    padding: 0 20px;
    font-weight: 700
}

.checkout {
    -webkit-align-self: flex-start;
    align-self: flex-start;
    margin: 10px;
    background-color: #fff;
    box-shadow: 0 2px 6px #555;
    padding: 15px;
    width: 400px
}

@media (max-width:450px) {
    .checkout {
        width: 330px;
        font-size: 14px
    }
}

@media (max-width:350px) {
    .checkout {
        width: 250px
    }
}

.checkout .heading {
    border-bottom: 2px solid #2b6777;
    padding: 10px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 700
}

.checkout .delivery,
.checkout .price,
.checkout .total {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 10px 10px 5px
}

.form-container {
    max-width: 500px;
    margin: 30px auto 0;
    padding: 20px 0;
    box-shadow: 0 2px 6px #555;
    border-radius: 25px
}

@media (max-width:550px) {
    .form-container {
        max-width: 380px
    }
}

@media (max-width:400px) {
    .form-container {
        max-width: 280px
    }
}

@media (max-width:300px) {
    .form-container {
        max-width: 240px
    }
}

.form-container h2 {
    text-align: center;
    color: #2b6777
}

.form-container form {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
    color: #2b6777
}

.form-container form a {
    color: #2b6777
}

.form-input {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    margin: 16px 0;
    padding: 0 20px;
    width: 95%
}

@media (max-width:550px) {
    .form-input {
        padding: 0
    }
}

.form-input label {
    -webkit-align-self: flex-start;
    align-self: flex-start;
    margin: 0 10px;
    font-weight: 700;
    letter-spacing: 1px
}

.form-input input {
    width: 95%;
    padding: 8px;
    border: 2px solid #e67e22;
    margin: 5px 10px;
    font-size: 18px;
    background-color: #eaeaea;
    outline: none
}

@media (max-width:400px) {
    .form-input input {
        width: 92%
    }
}

.form-btn {
    background-color: #2b6777;
    color: #f2f2f2;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    border-radius: 25px;
    width: 120px;
    outline: none;
    margin: 10px 0
}

@media (max-width:400px) {
    .form-btn {
        font-size: 16px;
        width: 100px
    }
}

.form-error {
    color: #c20d0d;
    margin-bottom: -20px;
    margin-left: 12px;
    font-weight: 700
}

.tooltip {
    position: relative;
    display: inline-block
}

.tooltip .tooltip-text {
    background-color: #c8d8e4;
    color: #2b6777;
    border: 1px solid #2b6777;
    width: 300px;
    font-size: 13px;
    position: absolute;
    top: 150%;
    left: 50%;
    border-radius: 10px;
    padding: 10px;
    margin-left: -110px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .7s
}

@media (max-width:400px) {
    .tooltip .tooltip-text {
        font-size: 11px;
        width: 260px
    }
}

@media (max-width:300px) {
    .tooltip .tooltip-text {
        margin-left: -120px
    }
}

.tooltip .tooltip-text:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -50px;
    border: 10px solid transparent;
    border-bottom-color: #2b6777
}

@media (max-width:400px) {
    .tooltip .tooltip-text:after {
        margin-left: -30px
    }
}

@media (max-width:300px) {
    .tooltip .tooltip-text:after {
        margin-left: -20px
    }
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1
}

.error-main {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
    min-height: 200px;
    min-width: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.error-main p {
    font-size: 22px
}

.error-main h1 {
    font-size: 50px
}

.error-main a {
    outline: none;
    background-color: #2b6777;
    color: #f2f2f2;
    padding: 10px 20px;
    border-radius: 20px
}

/*# sourceMappingURL=main.css.map */